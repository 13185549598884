import React, { useState } from 'react';
import { CheckCircle2, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import FeedbackForm from '../components/FeedbackForm';

function FDMPrinting() {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="space-y-20">
      {/* Hero Section */}
      <section className="relative h-[400px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ73LgPY5P18emju_U5xQdXBucRO9vBVDRA-g&s"
            alt="FDM Printing"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
          <h1 className="text-4xl font-bold mb-4">FDM Печать</h1>
          <p className="text-xl max-w-2xl">
            Технология послойного наплавления пластиковой нити для создания
            прочных функциональных изделий из различных типов термопластичных
            материалов.
          </p>
        </div>
      </section>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left Column - Description */}
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6">О технологии FDM</h2>
            <p className="text-gray-600">
              FDM <span lang="en">(Fused Deposition Modeling)</span> - это
              технология 3D-печати, основанная на послойном наплавлении
              пластиковой нити. Метод позволяет создавать прочные функциональные
              изделия с широким выбором материалов и цветов.
            </p>

            <h3 className="text-2xl font-semibold mt-8">
              Преимущества FDM печати:
            </h3>
            <ul className="space-y-3">
              {[
                'Доступная стоимость печати',
                'Широкий выбор материалов',
                'Высокая прочность изделий',
                'Возможность печати крупных объектов',
                'Быстрое прототипирование',
              ].map((item, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Right Column - Applications */}
          <div className="bg-gray-50 p-8 rounded-lg">
            <h3 className="text-2xl font-semibold mb-6">Области применения</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">Прототипирование</h4>
                <p className="text-gray-600">
                  Быстрое создание прототипов и тестовых моделей
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">Производство</h4>
                <p className="text-gray-600">
                  Изготовление функциональных деталей и механизмов
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">Образование</h4>
                <p className="text-gray-600">
                  Создание учебных моделей и демонстрационных образцов
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">Хобби</h4>
                <p className="text-gray-600">
                  Печать моделей, игрушек и декоративных элементов
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Materials Section */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold mb-8">Материалы для печати</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">PLA</h3>
              <p className="text-gray-600 mb-4">
                PLA (полилактид) — это экологичный биоразлагаемый пластик,
                изготовленный из кукурузного крахмал. Легко печатается, не
                деформируется и безопасен для домашнего использования. Подходит
                для создания прототипов, игрушек и декораций, но менее прочный и
                термостойкий, чем ABS.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">PETG</h3>
              <p className="text-gray-600 mb-4">
                Прочный и универсальный материал, который сочетает в себе
                гибкость и устойчивость к влаге, химическим веществам и
                ультрафиолету. Отлично подходит для создания функциональных
                деталей, контейнеров для хранения и элементов наружного
                применения.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">ABS</h3>
              <p className="text-gray-600 mb-4">
                Прочный и ударостойкий пластик, который выдерживает высокие
                температуры и механические нагрузки. Используется для создания
                функциональных деталей, корпусов электроники, автозапчастей. К
                минусам можно отнести относительно высокую усадку, низкую
                прочность вдоль слоев и отсутствие уф стойкости.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">PA12 (полиамид 12)</h3>
              <p className="text-gray-600 mb-4">
                Материал можно назвать КОРОЛЕМ 3D печати! Прочный и гибкий
                материал, обладающий высокой устойчивостью к износу,
                температуре, химическим воздействиям и влажности. Идеален для
                создания функциональных высоконагруженных деталей, элементов
                машиностроения и изделий, работающих в агрессивных средах.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">TPU</h3>
              <p className="text-gray-600 mb-4">
                Гибкий и эластичный термопластичный полиуретан, который обладает
                отличной устойчивостью к износу, химическим воздействиям и
                ударам. Идеален для создания амортизирующих элементов,
                прокладок, обувных подошв и гибких прототипов. Благодаря своей
                гибкости и долговечности, TPU используется в производстве
                спортивных товаров, автомобильных запчастей и медицинских
                изделий.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <p className="text-gray-600">
                Помимо описанных филаментов мы используем: дерево и
                метало-наполненный PLA, FLEX, PP, люминесцентный PLA и многие
                другие. Расскажите про вашу задачу, а мы подберём наилучший
                материал.
              </p>
            </div>
          </div>
        </section>

        {/* Gallery Link Section */}
        <section className="mt-20 mb-20 text-center">
          <Link
            to="/gallery?filter=FDM"
            onClick={scrollToTop}
            className="inline-flex items-center px-6 py-3 bg-secondary hover:bg-secondary-hover text-white rounded-lg font-medium transition-colors"
          >
            Посмотреть примеры работ FDM печати
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </section>
      </div>

      {/* Contact Form */}
      <FeedbackForm
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        isFloating={true}
      />
    </div>
  );
}

export default FDMPrinting;