import React from 'react';
import AboutHero from '../components/about/AboutHero';
import TeamSection from '../components/TeamSection';
import ContactSection from '../components/ContactSection';
import LegalInfo from '../components/about/LegalInfo';

function About() {
  return (
    <div className="space-y-20">
      <AboutHero />
      <TeamSection />
      <LegalInfo />
      <ContactSection />
    </div>
  );
}

export default About;