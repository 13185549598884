import React from 'react';
import { Settings, Zap, Heart } from 'lucide-react';

function AboutHero() {
  const principles = [
    {
      icon: <Settings className="h-8 w-8" />,
      text: ['Мы', 'слушаем вас, чтобы понять потребности.'],
      bgColor: 'bg-primary/20',
      iconColor: 'text-primary',
      bgHover: 'hover:bg-primary/30'
    },
    {
      icon: <Zap className="h-8 w-8" />,
      text: ['Мы', 'проектируем так, чтобы результат был уникальным.'],
      bgColor: 'bg-secondary/20',
      iconColor: 'text-secondary',
      bgHover: 'hover:bg-secondary/30'
    },
    {
      icon: <Heart className="h-8 w-8" />,
      text: ['Мы', 'печатаем быстро, качественно и с любовью к деталям'],
      bgColor: 'bg-primary/20',
      iconColor: 'text-primary',
      bgHover: 'hover:bg-primary/30'
    }
  ];

  return (
    <section className="bg-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            Команда мечтателей и создателей
          </h1>
          <p className="text-xl text-gray-600 mb-12">
            Нас немного, но этого достаточно, чтобы ваши идеи стали реальностью:
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {principles.map((principle, index) => (
            <div
              key={index}
              className={`${principle.bgColor} ${principle.bgHover} rounded-lg p-8 transition-all duration-300 transform hover:-translate-y-1`}
            >
              <div className="flex flex-col items-center text-center space-y-6">
                <div className={`${principle.iconColor}`}>
                  {principle.icon}
                </div>
                <p className="text-lg text-gray-700">
                  <span className="font-bold text-gray-900">{principle.text[0]}</span>{' '}
                  {principle.text[1]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AboutHero;