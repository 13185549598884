import React from 'react';
import { Building2, Phone, Mail } from 'lucide-react';

function LegalInfo() {
  return (
    <section className="bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">Юридическая информация</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold mb-6">Реквизиты компании</h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <Building2 className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                <div>
                  <p className="font-medium">ИП Хлыщев Михаил Дмитриевич</p>
                  <p className="text-gray-600">ИНН: 502910461681</p>
                  <p className="text-gray-600">ОГРНИП: 324774600303184</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold mb-6">Контактная информация</h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <Phone className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                <div>
                  <p className="font-medium">Телефон</p>
                  <p className="text-gray-600">+7 916 546-16-16</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <Mail className="h-5 w-5 text-secondary mt-1 flex-shrink-0" />
                <div>
                  <p className="font-medium">Email</p>
                  <p className="text-gray-600">info@im-do.ru</p>
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-2 bg-white p-8 rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold mb-6">Правовая информация</h3>
            <div className="space-y-4 text-gray-600">
              <p>
                Вся представленная на сайте информация носит информационный характер и не является публичной офертой.
              </p>
              <p>
                Все права на материалы, размещенные на сайте, защищены в соответствии с российским и международным законодательством об авторском праве и смежных правах.
              </p>
              <p>
                Любое использование материалов сайта допускается только с письменного согласия правообладателя.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LegalInfo;